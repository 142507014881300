<template>
  <div class="img-upload-box">
    <h4 class="img-upload-box-label">{{data.title}}</h4>
    <!-- file uploader input -->
    <input type="file" class="d-none" :id="`file-uploader-${data.id}`" :name="`file-uploader-${data.id}`" @change="addFile"/>
    <!-- box  -->
    <label class="m-0 w-100" :for="`file-uploader-${data.id}`">
      <div
        class="img-box d-flex align-items-center justify-content-center flex-column"
        :style="{backgroundImage: currentDoc ? `url(${currentDoc.url})` : null, backgroundSize: 'cover'}">
        <div class="img-inner" v-if="!loading && (!currentDoc || !currentDoc.url)">
          <i class="las la-image icon"></i>
          <slot name="first" />
        </div>
        <div class="img-inner" v-if="loading">
          <b-spinner label="loading..."></b-spinner>
        </div>
        <span class="types" v-if="!loading && (!currentDoc || !currentDoc.url)">( {{data.type}} )</span>
      </div>
      <div class="img-upload-box-btn mt-1 text-center py-1">اختر الصورة</div>
    </label>
  </div>
</template>

<script>
/*eslint-disable*/ 

import doctorApi from '../services/settings'

export default {
  props: ['data', 'index', 'src'],
  data() {
    return {
      currentDoc: this.src ? { url: this.src, doc_id: this.data.id } : null,
      loading: false,
    }
  },
  methods: {
    addFile(e) {
      this.loading = true
      const targetFile = e.target.files[0]
      const fileFormData = new FormData()
      fileFormData.append('file', targetFile)
      fileFormData.append('directory', 'doctors')
      fileFormData.append('app_name', 'veterinary')
      doctorApi.uploadDotorDocument(fileFormData).then(res => {
        this.currentDoc = { url: res.data.url, doc_id: this.data.id }
        this.$emit('uploadDocument', { url: res.data.url, doc_id: this.data.id })
        // Reset the input file value to allow uploading the same file again if needed
        e.target.value = ''
      }).catch(() => {
        this.currentDoc = null
      }).finally(() => {
        this.loading = false
      })
    }
  },
  watch: {
    src(newVal) {
      this.currentDoc = newVal ? { url: newVal, doc_id: this.data.id } : null
    }
  }
}
</script>